<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('deposits.form.addexpense')"
          :description="$t('deposits.form.FromHereYouCanCreateaNewexpense')"
          v-if="$route.name == 'depositsCreate'"
        />
        <addHeader
          :title="$t('deposits.form.expenseadjustment')"
          :description="
            $t('deposits.form.FromHereYouCanModifyTheexpenseInformation')
          "
          v-if="$route.name == 'depositsEdit'"
        />
        <div class="mb-4 row">
          <formInput
            :title="$t('deposits.form.OwnerIsName')"
            v-model="item.owner_name"
            :hasErorr="errors.owner_name"
            maxlength="255"
            error="$t('deposits.form.TheOwnerIsNameCanItBeEmpty')"
          />
          <formInput
            :title="$t('deposits.form.Converter')"
            v-model="item.converter_name"
            :hasErorr="errors.converter_name"
            maxlength="255"
            error="$t('deposits.form.TheNameOfTheAdapterFromHimCanItBeEmpty')"
          />
          <formInput
            :title="$t('deposits.form.ConverterTo')"
            v-model="item.converted_to"
            :hasErorr="errors.converted_to"
            maxlength="255"
            error="$t('deposits.form.TheNameOfTheAdapterCanItBeEmpty')"
          />
          <formInput
            :title="$t('deposits.form.accountNumber')"
            v-model="item.account_number"
            maxlength="255"
          />
          <formInput
            :title="$t('deposits.form.ID')"
            v-model="item.identification_number"
            maxlength="255"
          />
          <formInput 
            :title="$t('deposits.form.bankName')" 
            v-model="item.bank_name"
            maxlength="255"
            />
          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{ $t('endorsements.form.date') }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>

                <VueCtkDateTimePicker
                  :label="$t('deposits.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('deposits.form.Now')"
                  v-model="item.date"
                  :disabled="
                    $user.admin ||
                    $user.role.invoices_edit_product
                      ? false
                      : true
                  "
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>
          <formInput :title="$t('deposits.form.ValueOfTheSecretariat')" v-model="item.cost"/>

          <dvider
            :title="$t('deposits.form.expenseDetails')"
            :description="$t('deposits.form.DetailsOfTheDeclaration')"
          />

          <formTextarea :title="$t('deposits.form.notes')" v-model="item.notes"/>
          <!-- Divider -->
          <hr class="mt-5 mb-5 col-12" />
          <!-- Buttons -->
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'depositsCreate' && !$parent.stopEdit"
          >
            {{ $t('deposits.form.addexpense') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'depositsEdit' && !$parent.stopEdit"
          >
            {{ $t('deposits.form.expenseadjustment') }}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/deposits",
      item: {
        date: "",
      },
      customers: {},
      suppliers: {},
      safes: {},
      depositsections: {},
      paymentMethods: {},
      taxes: {},
      model: false,
      errors: {
        owner_name: false,
        converter_name: false,
        converted_to: false,
      },
      print: true,
    };
  },
  mounted() {
    if (this.$route.name == "depositsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    }

    if (this.$route.name == "depositsCreate") {
      this.item.date = this.$nowDate()
    }
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
      })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
        });
    },
    saveItem() {
      var error = 0;
      if (
        typeof this.item.owner_name === "undefined" ||
        this.item.owner_name == ""
      ) {
        error = 1;
        this.errors.owner_name = true;
      }
      if (
        typeof this.item.converter_name === "undefined" ||
        this.item.converter_name == ""
      ) {
        error = 1;
        this.errors.converter_name = true;
      }
      if (
        typeof this.item.converted_to === "undefined" ||
        this.item.converted_to == ""
      ) {
        error = 1;
        this.errors.converted_to = true;
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == "depositsCreate") {
          this.$parent.aletText =
            this.$t('allerts.depositsuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));          
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "depositsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText =
                this.$t('allerts.expenseHasBeenModifiedSuccessfully');
              this.$parent.alertType = "success";
              this.$router.go(-1);
              this.$parent.stopEdit = false;
              return response;
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
  components: {
    formInput,
    dvider,
    addHeader,
    formTextarea,
    loading,

    VueCtkDateTimePicker
  },
};
</script>
